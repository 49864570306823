<template>
  <div class="zhihuizhengwu">
    <div class="biaoti">
      <span class="el-icon-caret-bottom"></span>
      方案介绍
    </div>
    <div class="jieshao mt20">
      汉康智慧政务解决方案是依托互联网平台，以工程化、项目化方式，充分运用“互联网+”思维，着力推进“互联网+政务服务”体系建设。坚持“简政放权、放管结合、优化服务”的行政审批制度改革思路，创新“受办分离”、“审定分离”工作理念，通过建成线上线下一体化智慧政务服务平台，推进“一号申请、一窗受理、一网通办”，实现审批服务一窗登记、一网通办、一窗取件、智能引导、全程跟踪、数据共享、中介服务、证照共享、实时评价等功能，着力解决联系服务群众“最后一公里”问题，提高行政办事服务效率，积极营造一流的政务服务环境，切实增强群众获得感，让企业和群众少跑腿、好办事、不添堵，提升幸福指数。
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      方案架构
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/fangan/zhengwu/1.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      方案主要构成模块介绍
    </div>
    <div class="jieshao mt20">
      政务服务数据资源管理系统：依托互联网政务服务门户网实现一般事项在线咨询、申请、受理、审批、公示、查询、反馈、投诉和评价全流程办理，让群众办事像网上购物一样方便。
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">政务服务数据资源管理系统</div>
      <div class="li-text">
        依托互联网政务服务门户网实现一般事项在线咨询、申请、受理、审批、公示、查询、反馈、投诉和评价全流程办理，让群众办事像网上购物一样方便。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">一窗式行政审批系统</div>
      <div class="li-text">
        系统结合“一号一窗一网”工作机制，实现了对政务服务事项运行的全流程电子化审批，涵盖了“咨询、预约、申请、审批、办结、收费、制证、评价、物流送达”等审批环节。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">智慧政务移动应用</div>
      <div class="li-text">
        主要面向公众、政务工作人员提供移动端的政务服务应用功能，包括移动服务系统以及微信服务应用等。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">并联审批系统</div>
      <div class="li-text">
        实现并联审批事项的系统办理，规范相关部门在并联审批办理中的行为并提升效率。可支撑企业设立登记多证合一、投资项目并联审批、投资项目多评合一、投资项目多图联审等拓展应用。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">审管互动平台</div>
      <div class="li-text">
        实现并联审批事实现审批、监管、执法各司其职又相互配合，实时信息共享、在线告知等方式，建立与监管、执法的信息抄告、回复机制，推进审批与监管、执法的无缝对接，形成与监管、执法既相互监督又相互促进的工作格局。项的系统办理，规范相关部门在并联审批办理中的行为并提升效率。可支撑企业设立登记多证合一、投资项目并联审批、投资项目多评合一、投资项目多图联审等拓展应用。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">电子证照管理系统</div>
      <div class="li-text">
        推进各类审批业务系统、制证系统与电子证照库对接联通，实现证照的同步签发、入库、共享、应用。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">服务诉求平台</div>
      <div class="li-text">
        企业、群众可以在线提交留言、咨询、建议等诉求信息，并同步展示诉求服务平台的处理全过程。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">监督考评系统</div>
      <div class="li-text">
        依据行政审批绩效管理办法对行政审批机关工作人员、审批单位的政务公开、流程规范、服务态度、处理情况等进行客观的评判，实现对行政主体、内容、程序的“事前、事中、事后”的全面监察。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">领导决策分析系统</div>
      <div class="li-text">
        基于系统运行沉淀的大数据，并结合政务服务的实际业务需求，为政府的政务工作提供大数据决策支持。
      </div>
    </div>

    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      方案优势
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">实现一号申请、一窗受理、一网通办</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">
        完成政务服务线上线下融合、审批全程留痕、审管分离
      </div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">搭建跨区域、跨部门、跨层级的信息共享</div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.zhihuizhengwu {
  width: 870px;
  padding-bottom: 60px;
  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>